.paymentForm {
    background-color: var(--background);
    border-radius: 10px;
    padding: 0 1rem 1rem 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}
