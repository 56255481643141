.pageContainer {
    background-color: var(--background);
    display: flex;
    justify-content: center;
    align-content: center;
    position: relative;
    flex-direction: column;

    width: 100vw;
    min-height: 100vh;
}

.pageContainerHidden {
    
    background-color: var(--background);
    display: flex;
    justify-content: center;
    align-content: center;
    position: relative;
    flex-direction: column;

    width: 100vw;
    height: 100vh;
    overflow: hidden;

}

.headerContainer {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: fit-content;
    align-self: center;
}

h1 {
    align-self: center;
    color: var(--text);
}

.errorMessage {
    align-self: center;
    color: red;
}

.isSubscriberModalContainer {
    padding: 1rem;
    border-radius: 10px;
    background-color: var(--background);
    color: var(--text);

    display: flex;
    flex-direction: column;
    align-items: center;
}

.isSubscriberModalContainer p{
    margin-top: 0;
    color: var(--text_alpha);
}

.isSubscriberModalContainer div {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    margin-top: 1rem;
}

.pricingContainer {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    justify-content: center;
    flex-wrap: wrap;
}

.pricingTier {
    position: relative;
    padding: 1rem 3rem 1rem 3rem;
    box-shadow: 0 0 20px #1a1919;
    -webkit-box-shadow: 0 0 20px #1a1919;
    -moz-box-shadow: 0 0 20px #1a1919;
    border-radius: 10px;
    color: var(--text_alpha);

    display: flex;
    flex-direction: column;
    align-items: center;
}

.pricingTier h2 {
    color: var(--text);
    padding-bottom: 1rem;
    letter-spacing: -0.5px;
    border-bottom: 1px solid var(--accent);
    text-align: center;
}

.price {
    font-size: 1.3rem;
    letter-spacing: -0.5px;
    color: var(--text);
}

button {
    background-color: var(--dark);
    border: none;
    outline: none;
    font-size: 1rem;
    width: fit-content;
    padding: 1rem 3rem 1rem 3rem;
    border-radius: 10px;
    justify-self: center;
    align-self: center;
    color: var(--text);
}

button:hover {
    background-color: var(--accent);
    cursor: pointer;
}

.back {
    position: absolute;
    color: var(--text_alpha);
    top: 50%;
    transform: translateY(-50%);
    left: -2rem;
    height: 1.5rem;
    width: 1.5rem;
}

.back:hover {
    color: var(--accent);
    cursor: pointer;
}
