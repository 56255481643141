.container {
    padding: 2rem;
    border-radius: 10px;
    box-shadow: 0 0 20px #1a1919;
    -webkit-box-shadow: 0 0 20px #1a1919;
    -moz-box-shadow: 0 0 20px #1a1919;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
}

.loadedImage {
    height: 200px;
    aspect-ratio: 1;
}

.download {
    background-color: var(--dark);
    width: 150px;
    padding: 0.5rem;
    border-radius: 10px;
    color: #fff;
    text-decoration: none;
}

.download:hover {
    cursor: pointer;
    background-color: var(--accent);
}

.save,
.saved {
    font-size: 0.8rem;
    color: #ebebf088;
}

.saved:hover {
    cursor: default;
}

.save:hover {
    cursor: pointer;
}
