.container {
    height: 100vh;
    width: 100vw;
    background-color: var(--background);
    color: var(--text);

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.container h1 {
    font-size: 2rem;
    border-bottom: 1px solid var(--accent);
    padding: 1rem;
    margin-bottom: 1rem;
}

.container p {
    color: var(--text_alpha);
    margin-bottom: 1rem;
}

.container button {
    padding: 1rem;
    border-radius: 10px;
}
.container button:hover {
    cursor: pointer;
    background-color: var(--accent);
}