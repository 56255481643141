.pageContainer {
    display: flex;
    flex-direction: row;
    height: 100vh;
    width: 100vw;
}

.sidebar {
    position: relative;
    background-color: var(--dark);
    width: 14rem;
    padding: 0.5rem;
}

.notLoggedIn {
    color: #ebebf088;
    text-align: center;
    margin-top: 8rem;
}

.queryContainer {
    color: var(--text);
    letter-spacing: -0.5px;

    width: 90%;
    padding: 12px 5px 12px 5px;
    border-radius: 5px;

    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    position: relative;
}

.queryTextContainer {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 100%;
}

.queryContainer:hover,
.queryContainerSelected {
    background-color: var(--background);
    cursor: pointer;
}

.trashIcon {
    display: none;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
}

.queryContainer:hover .trashIcon,
.queryContainerSelected .trashIcon {
    display: flex;
}

.queryContainer:hover .queryTextContainer,
.queryContainerSelected .queryTextContainer {
    width: 90%;
}

.queryContainerSelected .queryTextContainer {
    text-overflow: ellipsis;
    white-space: normal;
    overflow: visible;
}

.confirmDelete {
    position: absolute;
    z-index: 1;
    background-color: var(--background);
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    color: #fff;

    display: none;
    flex-direction: row;
    gap: 1rem;
    align-items: center;
    justify-content: center;
}

.confirmDelete span {
    font-size: 0.8rem;
}

.confirmed {
    background-color: red;
    padding: 0.5rem;
    border-radius: 10px;
}

.navModal {
    position: absolute;
    bottom: 4rem;
    left: 50%;
    transform: translateX(-50%);
    width: 90%;
    padding: 0.5rem 0 0.5rem 0;
    border-radius: 5px;
    z-index: 1;
    background-color: #000;
    color: var(--text);

    display: flex;
    flex-direction: column;
}

.navModalLink {
    padding: 0.5rem;
}

.navModalLink .login {
    color: var(--text);
    letter-spacing: -0.5px;
}

.loginButton {
    padding: 0.5rem;
    color: var(--text);
}

.addBreak {
    border-top: 1px solid var(--text);
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}

.navModalLink:hover,
.loginButton:hover,
.register:hover {
    cursor: pointer;
    background-color: var(--background);
}

.tokenCountText {
    padding: 0.5rem;
    color: var(--text_alpha);
    font-size: 0.8rem;
}

.account {
    position: absolute;
    bottom: 0;
    border-top: 1px solid var(--text);
    left: 50%;
    transform: translateX(-50%);
    height: 5rem;
    width: 90%;

    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}

.account .login {
    padding: 8px 30px 8px 30px;
    border-radius: 10px;
    background-color: var(--background);
    color: var(--text);
    letter-spacing: -0.5px;
}

.account .login:hover {
    cursor: pointer;
    background-color: rgb(255, 0, 106);
    color: #000;
}

.account .register {
    color: #fff;
    font-size: 0.8rem;
}

.content,
.contentFullWidth {
    background-color: var(--background);
    width: calc(100vw - 15rem);
    color: var(--text);

    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    position: relative;
}

.contentFullWidth {
    width: 100vw;
}

.toggleSidebar {
    position: absolute;
    top: 1rem;
    left: 1rem;
}

.toggleSidebarClose {
    position: absolute;
    top: 1rem;
    right: 1.6rem;
    z-index: 2;
}

.toggleMenuIcon {
    height: 30px;
    width: 30px;
    color: var(--text);
}

.toggleMenuIcon:hover {
    cursor: pointer;
    color: var(--accent);
}

.welcome {
    margin: 10rem 0 0 0;
    letter-spacing: -0.5px;
}

.welcomeNotLoggedIn {
    margin: 2rem 0 1rem 0;

    letter-spacing: -0.5px;
}

.loadingContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
}

.loadingText {
    font-size: 0.8rem;
    color: var(--text_alpha);
}

.loadingAnimation {
    height: 10rem;
    aspect-ratio: 1;
}

.takingTooLong {
    margin-top: 0.5rem;
    font-size: 0.8rem;
    color: var(--accent);
}

.takingTooLong:hover {
    cursor: pointer;
}

.tooltip {
    background-color: #000000ee;
    width: 200px;
    position: absolute;
    z-index: 1;
    font-size: 0.8rem;
    padding: 0.5rem;
    border-radius: 5px;
    bottom: 1rem;
}

.examplesContainer {
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.imgContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 90%;
}

.loadedImage {
    height: 200px;
    aspect-ratio: 1;
}

.promptContainer {
    position: absolute;
    bottom: 0;
    padding: 1rem;
    width: 90%;
    max-width: 600px;
}

.promptBar {
    position: relative;
    width: 100%;
    margin-top: 10px;
}

.prompt {
    padding: 1.3rem;
    width: calc(100% - 2.6rem);
    background-color: var(--background);
    border-radius: 10px;
    border: none;

    outline: none;
    box-shadow: 0 0 20px #1a1919;
    -webkit-box-shadow: 0 0 20px #1a1919;
    -moz-box-shadow: 0 0 20px #1a1919;
    font-size: 16px;
    font-family: Söhne, ui-sans-serif, system-ui, -apple-system, Segoe UI, Roboto, Ubuntu, Cantarell,
        Noto Sans, sans-serif, Helvetica Neue, Arial, Apple Color Emoji, Segoe UI Emoji,
        Segoe UI Symbol, Noto Color Emoji;
    color: var(--text);
}

.submitSvg {
    position: absolute;
    top: 50%;
    right: 1rem;
    transform: translateY(-50%);
}

.submitSvg:hover {
    cursor: pointer;
    color: var(--accent);
}

.promptContainer p {
    font-size: 0.7rem;
    color: var(--text);
}

@media only screen and (max-width: 520px) {
    .content {
        width: 100%;
        overflow: hidden;
    }

    .sidebar {
        width: calc(100% - 1rem);
        height: calc(100% - 1rem);
        overflow: hidden;
        position: absolute;
        z-index: 1;
    }
}
