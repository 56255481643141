.exampleContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
}

.container {
    padding: 2rem;
    border-radius: 10px;
    box-shadow: 0 0 20px #1a1919;
    -webkit-box-shadow: 0 0 20px #1a1919;
    -moz-box-shadow: 0 0 20px #1a1919;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 1rem;
}

.loadedImage {
    height: 200px;
    aspect-ratio: 1;
}

.reload {
    height: 2rem;
    width: 2rem;
    color: var(--text);
}

.reload:hover {
    cursor: pointer;
    color: var(--accent);
}

@media only screen and (max-width: 1024px) {
    .exampleContainer {
        flex-direction: column;
        height: calc(100vh - 23rem);
        overflow-y: auto;
        justify-content: flex-start;
    }
}
