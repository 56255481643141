.pageContainer {
    background-color: var(--background);
    display: flex;
    justify-content: center;
    align-content: center;
    position: relative;

    height: 100vh;
    width: 100vw;
}

.loginContainer {
    box-shadow: 0 0 20px #1a1919;
    -webkit-box-shadow: 0 0 20px #1a1919;
    -moz-box-shadow: 0 0 20px #1a1919;

    display: flex;
    flex-direction: column;
    justify-content: center;
    height: fit-content;
    justify-self: center;
    align-self: center;
    padding: 2rem;
    border-radius: 10px;
    position: relative;
}

.back {
    position: absolute;
    color: var(--text_alpha);
    top: 1rem;
    left: 1rem;
    height: 1.5rem;
    width: 1.5rem;
}

.back:hover {
    color: var(--accent);
    cursor: pointer;
}

.loginContainer h1 {
    text-align: center;
    letter-spacing: -0.5px;
    color: var(--text);
    border-bottom: 1px solid var(--accent);
    padding-bottom: 1rem;
    font-size: 2rem;
}

.subheader {
    text-align: center;
    color: var(--text);
}

.textInput {
    font-size: 1rem;
    padding: 1rem 0.5rem 1rem 0.5rem;
    box-shadow: 0 0 20px #1a1919;
    -webkit-box-shadow: 0 0 20px #1a1919;
    -moz-box-shadow: 0 0 20px #1a1919;

    background-color: var(--dark);
    border: none;
    outline: none;
    color: var(--text);
    margin-bottom: 1rem;
    border-radius: 10px;
    max-width: 300px;
    width: 90%;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    border: none;
    -webkit-text-fill-color: var(--text);
    -webkit-box-shadow: 0 0 0px 1000px var(--dark) inset;
    transition: background-color 5000s ease-in-out 0s;
}

.errorMessage {
    color: red;
    text-align: center;
    font-size: 0.9rem;
}

.submit {
    background-color: var(--dark);
    border: none;
    outline: none;
    font-size: 1rem;
    width: fit-content;
    padding: 1rem 3rem 1rem 3rem;
    border-radius: 10px;
    justify-self: center;
    align-self: center;
    color: var(--text);
}

.submit:hover {
    background-color: var(--accent);
    cursor: pointer;
}

@media only screen and (max-width: 300px) {
    .loginContainer h1 {
        font-size: 1.5rem;
    }

    .pageContainer {
        font-size: 0.9rem;
    }
}
