html {
    background-color: var(--background);
}

.pageContainer {
    color: var(--text);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.header {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;
    width: fit-content;
    justify-self: center;
}

.back {
    position: absolute;
    height: 1.5rem;
    width: 1.5rem;
    left: -3rem;
}

.back:hover {
    cursor: pointer;
    color: var(--accent);
}

.subheader {
    color: var(--text_alpha);
    text-align: center;
    border-bottom: 1px solid var(--accent);
    padding-bottom: 1rem;
}

.subscriptionContainer {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.modal {
    display: flex;
    flex-direction: column;
    align-items: center;

    background-color: var(--background);
    border-radius: 10px;
    padding: 1rem;
    gap: 1rem;
}

.modalButtonContainer {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.modal h2 {
    border-bottom: 1px solid var(--accent);
    padding: 1rem;
    margin-bottom: 0;
}

.subscriptionContainer span {
    align-self: center;
}

.loadingAnimation {
    height: 100px;
    width: 100px;
}

.subscriptionDetails {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
}

.subscriptionDetails p {
    color: var(--text_alpha);
    margin-top: 0;
}

td:nth-child(odd) {
    text-align: left;
}
td:nth-child(even) {
    text-align: right;
}

table {
    border-top: 1px solid var(--accent);
    border-bottom: 1px solid var(--accent);
}

td {
    padding: 0.5rem;
}